/* import __COLOCATED_TEMPLATE__ from './conversation-resource.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Conversation from 'embercom/objects/inbox/conversation';
import ConversationSummary from 'embercom/objects/inbox/conversation-summary';
import type InboxState from 'embercom/services/inbox-state';
import type InboxApi from 'embercom/services/inbox-api';
import { inject as service } from '@ember/service';
import { type DurationObject } from 'embercom/objects/inbox/duration';
import { type BlockList } from '@intercom/interblocks.ts';
import { type MacroAction } from 'embercom/objects/inbox/macro';
import { ComposerPaneType, type ReplyChannelType } from 'embercom/objects/inbox/composer-pane';
import type Nexus from 'embercom/services/nexus';
import { NexusEventName, NexusFallbackPoller } from 'embercom/services/nexus';
import type Session from 'embercom/services/session';
import { isEmpty } from '@ember/utils';
/* eslint-disable @intercom/intercom/no-component-inheritance */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { restartableTask, task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator, timeout } from 'ember-concurrency';

import { registerDestructor } from '@ember/destroyable';
import { noCache } from 'embercom/lib/cached-decorator';
import {
  type SendAndCloseFn,
  type SendAndSetStateFn,
  type SendAndSnoozeFn,
} from 'embercom/objects/inbox/types/composer';
import moment from 'moment-timezone';
import type ApplicationInstance from '@ember/application/instance';
import { type UpdateMessage } from 'embercom/services/conversation-updates';
import type ConversationUpdates from 'embercom/services/conversation-updates';
import type TracingService from 'embercom/services/tracing';
import { updateExpiringImages } from 'embercom/lib/inbox2/conversation-reload-helpers';
import Component from '@glimmer/component';
import ConversationTableEntry, {
  type TableEntryHighlights,
} from 'embercom/objects/inbox/conversation-table-entry';
// @ts-ignore
import { dedupeTracked } from 'tracked-toolbox';
import { type RequestInitWithPriority, requestWithRetries } from 'embercom/lib/inbox/requests';
import { RenderableType } from 'embercom/models/data/inbox/renderable-types';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import UserComment, { UserContentSeenState } from 'embercom/objects/inbox/renderable/user-comment';
import ENV from 'embercom/config/environment';
import type LbaMetricsService from 'embercom/services/lba-metrics-service';
import { LbaTriggerEvent } from 'embercom/services/lba-metrics-service';
import { captureException } from 'embercom/lib/sentry';
import type ConversationTranslationSettings from 'embercom/services/conversation-translation-settings';
import { type RecipientsWireFormat } from 'embercom/lib/composer/recipients';
import type ConversationStreamSettings from 'embercom/services/conversation-stream-settings';
import LinkedTicketsResource from './linked-tickets-resource';

const BACKGROUND_RELOAD_BACKOFF_INCREMENT = 1000;
const BACKGROUND_RELOAD_MAX_BACKOFF = 5000;
const BACKGROUND_RELOAD_TIMEOUT = 1000;

type ResourceArgs =
  | { conversationId: number; clientAssignedUuid?: string }
  | {
      conversation: Conversation | ConversationSummary | ConversationTableEntry;
      highlights?: TableEntryHighlights;
    };

export class ConversationResource extends Resource<Named<ResourceArgs>> {
  @service declare inboxApi: InboxApi;
  @service declare inboxState: InboxState;
  @service declare nexus: Nexus;
  @service declare session: Session;
  @service declare conversationUpdates: ConversationUpdates;
  @service declare tracing: TracingService;
  @service declare lbaMetricsService: LbaMetricsService;
  @service declare conversationTranslationSettings: ConversationTranslationSettings;
  @service declare conversationStreamSettings: ConversationStreamSettings;

  #conversationId?: number;
  #poller = new NexusFallbackPoller(this);
  #lastMousemoveTime: number = Date.now();

  @tracked declare conversation: Conversation;
  @tracked declare highlights?: TableEntryHighlights;
  @dedupeTracked failedToLoad = false;

  linkedTicketsResource: LinkedTicketsResource;

  constructor(owner: ApplicationInstance) {
    super(owner);

    this.linkedTicketsResource = LinkedTicketsResource.from(this, () => ({
      conversation: this.conversation,
    }));

    let mousemoveListener = this.onMousemove.bind(this);
    addEventListener('mousemove', mousemoveListener);

    registerDestructor(this, () => {
      taskFor(this.fetchConversation).cancelAll();
      taskFor(this.reloadConversation).cancelAll();
      taskFor(this.maybeUpdateSeenState).cancelAll();
      removeEventListener('mousemove', mousemoveListener);
      this.#poller.stop();

      if (this.#conversationId) {
        this.conversationUpdates.unsubscribe(this.onConversationUpdate);
      }
    });
  }

  modify(_: unknown[], args: ResourceArgs) {
    let id = 'conversationId' in args ? args.conversationId : args.conversation?.id;
    if (id === this.#conversationId) {
      return;
    }

    let createdAt = 'conversationId' in args ? undefined : args.conversation?.createdAt;
    this.startTraceForConversation(id, createdAt);
    // Stop any pending work for the previous conversation
    this.highlights = undefined;
    taskFor(this.reloadConversation).cancelAll();
    taskFor(this.maybeUpdateSeenState).cancelAll();
    if (this.#conversationId) {
      this.conversationUpdates.unsubscribe(this.onConversationUpdate);
    }

    // Start work for the new conversation
    this.#conversationId = id;
    this.failedToLoad = false;
    this.#poller.start(() => taskFor(this.reloadConversation).perform());
    this.conversationUpdates.subscribe(this.onConversationUpdate);

    if ('conversationId' in args) {
      taskFor(this.fetchConversation).perform();
      return;
    }

    let { conversation } = args;
    if (
      conversation instanceof ConversationSummary ||
      conversation instanceof ConversationTableEntry
    ) {
      this.conversation = this.inboxState.createTemporaryConversationFromSummary(conversation);
      taskFor(this.fetchConversation).perform();
    } else {
      this.conversation = conversation;
    }

    if (args.highlights) {
      this.highlights = args.highlights;
    }
  }

  get hasError() {
    return this.failedToLoad;
  }

  get isLoading() {
    return taskFor(this.fetchConversation).isRunning;
  }

  @action
  reload(clientAssignedUuid?: string) {
    taskFor(this.reloadConversation).perform({ clientAssignedUuid, lowPriority: true });
  }

  @action
  manualReload(clientAssignedUuid?: string) {
    taskFor(this.reloadConversation).perform({ clientAssignedUuid, skipRetries: true });
  }

  @action snoozeConversation(duration: DurationObject) {
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(LbaTriggerEvent.SNOOZE);
    this.inboxState.snoozeConversation(this.conversation, duration);
  }

  @action closeConversation() {
    this.inboxState.closeConversation(this.conversation);
  }

  @action openConversation() {
    this.inboxState.openConversation(this.conversation);
  }

  @action startTraceForConversation(id: Conversation['id'], createdAt?: Conversation['createdAt']) {
    this.tracing.startRootSpan({
      name: 'customMeasurement',
      resource: 'inbox2:conversation_stream',
      attributes: {
        conversation_id: id,
        vertical_collection_used: true,
        conversation_created_at: createdAt?.getTime(),
        conversation_resource_type: 'main',
        conversation_events_shown: !this.conversationStreamSettings.hideEvents,
      },
    });
  }

  @action async sendReplyOrNote(
    type: ComposerPaneType,
    blocks: BlockList,
    macroActions: Array<MacroAction>,
    _channel?: ReplyChannelType,
    replyData?: any,
    recipients?: RecipientsWireFormat,
    crossPost = false,
    emailHistoryMetadataId?: number,
  ) {
    let { conversation } = this;

    if (blocks.length > 0) {
      if (type === ComposerPaneType.Reply) {
        await this.inboxState.replyToConversation(
          conversation,
          blocks,
          replyData,
          recipients,
          emailHistoryMetadataId,
        );
      } else {
        await this.inboxState.addNoteToConversation(conversation, blocks, crossPost);
      }
    }

    if (macroActions.length > 0) {
      await this.inboxState.applyMacroActions(conversation, macroActions);
    }
  }

  sendAndSnooze: SendAndSnoozeFn = async (
    blocks,
    macroActions,
    duration,
    _,
    replyData,
    recipients,
    emailHistoryMetadataId?: number,
  ) => {
    this.lbaMetricsService.trackTeammateMaybeWaitingForNewConversationAt(
      LbaTriggerEvent.SEND_AND_SNOOZE,
    );
    this.inboxState.replyAndSnooze(
      this.conversation,
      blocks,
      macroActions,
      duration,
      replyData,
      recipients,
      emailHistoryMetadataId,
    );
  };

  sendAndSetState: SendAndSetStateFn = async (
    blocks,
    macroActions,
    state,
    _,
    replyData,
    recipients,
    emailHistoryMetadataId?: number,
  ) => {
    this.inboxState.replyAndSetState(
      this.conversation,
      blocks,
      macroActions,
      state,
      replyData,
      recipients,
      emailHistoryMetadataId,
    );
  };

  sendAndClose: SendAndCloseFn = async (
    blocks,
    macroActions,
    _,
    replyData,
    recipients,
    emailHistoryMetadataId?: number,
  ) => {
    return this.inboxState.replyAndClose(
      this.conversation,
      blocks,
      macroActions,
      replyData,
      recipients,
      emailHistoryMetadataId,
    );
  };

  @action adminIsTyping(type: ComposerPaneType) {
    let adminIsTypingEventData = {
      conversationId: this.conversation.id.toString(),
      adminName: this.session.teammate.name,
      hasDefaultAvatar: isEmpty(this.session.teammate.imageURL),
      adminAvatar: this.session.teammate.imageURL,
      adminId: this.session.teammate.id.toString(),
    };

    if (type === ComposerPaneType.Note) {
      this.nexus.sendEvent(NexusEventName.AdminIsTypingANote, adminIsTypingEventData);
    } else {
      this.nexus.throttleSendUserEvent(
        this.conversation.userSummary.id,
        NexusEventName.AdminIsTyping,
        adminIsTypingEventData,
      );
      this.updateSeenState();
    }
  }

  // Triggered by mousemove/conversation change and checks first whether an admin has already replied to a conversation
  @task({ drop: true })
  *maybeUpdateSeenState(): TaskGenerator<void> {
    yield timeout(ENV.APP.userContentSeenStateDelay);

    let mouseMovedWithinDelay =
      ENV.environment === 'test' ||
      Date.now() - this.#lastMousemoveTime < ENV.APP.userContentSeenStateDelay;
    if (
      this.conversation.humanAdminComments.length > 0 &&
      !document.hidden &&
      mouseMovedWithinDelay
    ) {
      yield this.updateSeenState();
    }
  }

  // Trigger directly when an admin starts replying to any conversation
  @action async updateSeenState() {
    let lastUserComment = this.conversation.lastUserComment;
    if (
      !lastUserComment ||
      !(lastUserComment.renderableData instanceof UserComment) ||
      lastUserComment.renderableData.seenByCurrentAdmin === UserContentSeenState.Seen
    ) {
      return;
    }

    let renderableData = lastUserComment.renderableData;
    renderableData.setSeenByCurrentAdmin(UserContentSeenState.Seen);

    let partId = lastUserComment.generatePermalinkId(this.conversation.id);
    try {
      await this.inboxApi.updateSeenState(partId);
      this.nexus.sendUserEvent(
        this.conversation.userSummary.id,
        NexusEventName.UserContentSeenByAdmin,
        { conversationId: this.conversation.id.toString() },
      );
    } catch (e) {
      renderableData.setSeenByCurrentAdmin(UserContentSeenState.Unseen);
    }
  }

  @restartableTask
  private *fetchConversation(
    {
      skipCache,
      clientAssignedUuid,
      skipRetries,
      lowPriority,
    }: {
      skipCache: boolean;
      clientAssignedUuid?: string;
      skipRetries: boolean;
      lowPriority: boolean;
    } = {
      skipCache: false,
      skipRetries: false,
      lowPriority: false,
    },
  ): TaskGenerator<void> {
    if (typeof this.#conversationId === 'undefined') {
      throw new Error('fetchConversation cannot be called without a conversationId');
    }

    let inboxApi = this.inboxApi;
    if (skipCache) {
      inboxApi = noCache(this.inboxApi);
    }

    let controller = new AbortController();
    let priority: RequestInitWithPriority['priority'] = lowPriority ? 'low' : 'auto';

    let remoteConversation: Conversation;
    try {
      remoteConversation = yield requestWithRetries(
        () =>
          inboxApi.fetchConversation(this.#conversationId!, clientAssignedUuid, {
            priority,
            signal: controller.signal,
          }),
        {
          signal: controller.signal,
          retries: skipRetries ? 0 : Infinity,
          backoffDelay: BACKGROUND_RELOAD_BACKOFF_INCREMENT,
          maxBackoffDelay: BACKGROUND_RELOAD_MAX_BACKOFF,
          onFailedRequest: () => {
            this.failedToLoad = true;
          },
        },
      );
    } finally {
      controller.abort();
    }

    this.failedToLoad = false;

    // If the local conversation had a more recent last seen timestamp, let's keep that.
    if (this.isRemoteConversationLastSeenTimestampStale(this.conversation, remoteConversation)) {
      remoteConversation.updateLastSeenByUserAt(this.conversation.lastSeenByUserAt);
    }

    // If the local conversation has images which aren't already expired, let's keep them.
    if (this.conversation && remoteConversation) {
      updateExpiringImages(this.conversation, remoteConversation);
    }

    this.conversationUpdates.dropCommittedUpdates(remoteConversation);
    this.conversationUpdates
      .updatesFor(remoteConversation.id)
      .forEach((update) => update.apply(remoteConversation));
    this.tracing.tagRootSpan({
      conversation_id: this.#conversationId,
      conversation_renderable_part_count: remoteConversation.renderableParts.length,
      vertical_collection_used: true,
      conversation_has_email_parts: remoteConversation.renderableParts.some(
        (renderablePart: RenderablePart) =>
          renderablePart.renderableType === RenderableType.UserEmailComment,
      ),
    });

    this.addDirtyAttributeUpdates(remoteConversation);
    this.conversation = remoteConversation;
    taskFor(this.maybeUpdateSeenState).perform();
  }

  addDirtyAttributeUpdates(remoteConversation: Conversation) {
    if (this.conversation?.isTicket) {
      this.ticketAttributesDirtyUpdates(remoteConversation);
      return;
    }

    this.conversationAttributesDirtyUpdates(remoteConversation);
  }

  ticketAttributesDirtyUpdates(remoteConversation: Conversation) {
    try {
      remoteConversation.ticketAttributes = remoteConversation.ticketAttributes.map((attr) => {
        let localAttribute = this.conversation?.ticketAttributesById[attr.descriptor.id];
        if (attr.value !== localAttribute?.value && localAttribute?.isUpdated) {
          attr.value = localAttribute?.value;
        }

        return attr;
      });
    } catch (error) {
      captureException(error);
    }
  }

  conversationAttributesDirtyUpdates(remoteConversation: Conversation) {
    try {
      remoteConversation.attributes = remoteConversation.attributes.map((attr) => {
        let localAttribute = this.conversation?.attributesById[attr.descriptor.id];
        if (attr.value !== localAttribute?.value && localAttribute?.isUpdated) {
          attr.value = localAttribute?.value;
        }

        return attr;
      });
    } catch (error) {
      captureException(error);
    }
  }

  @task({ keepLatest: true })
  private *reloadConversation(
    {
      skipRetries,
      clientAssignedUuid,
      lowPriority,
    }: {
      skipRetries?: boolean;
      clientAssignedUuid?: string;
      lowPriority?: boolean;
    } = { skipRetries: false, lowPriority: false },
  ): TaskGenerator<void> {
    yield taskFor(this.fetchConversation).perform({
      skipCache: true,
      clientAssignedUuid,
      skipRetries: !!skipRetries,
      lowPriority: !!lowPriority,
    });
    yield timeout(BACKGROUND_RELOAD_TIMEOUT);
  }

  private isRemoteConversationLastSeenTimestampStale(
    localConversation: Conversation,
    remoteConversation: Conversation,
  ): boolean {
    if (!localConversation || !remoteConversation) {
      return false;
    }

    if (localConversation.lastSeenByUserAt && !remoteConversation.lastSeenByUserAt) {
      return true;
    } else if (!localConversation.lastSeenByUserAt && remoteConversation.lastSeenByUserAt) {
      return false;
    } else {
      return moment(localConversation.lastSeenByUserAt).isAfter(
        moment(remoteConversation.lastSeenByUserAt),
      );
    }
  }

  @action
  private onConversationUpdate(updates: UpdateMessage[]) {
    updates.forEach((update) => {
      if (update.conversationId !== this.#conversationId) {
        return;
      }

      if (update.type === 'added') {
        update.entries.forEach((update) => update.apply(this.conversation));
      } else if (update.type === 'removed') {
        update.entries.forEach((update) => update.rollback(this.conversation));
      }
    });
  }

  private onMousemove() {
    let lastUserComment = this.conversation?.lastUserComment;
    this.#lastMousemoveTime = Date.now();
    if (
      !taskFor(this.maybeUpdateSeenState).isRunning &&
      lastUserComment &&
      lastUserComment.renderableData instanceof UserComment &&
      lastUserComment.renderableData.seenByCurrentAdmin !== UserContentSeenState.Seen
    ) {
      taskFor(this.maybeUpdateSeenState).perform();
    }
  }
}

export default class ConversationResourceComponent extends Component<{
  Args: ResourceArgs;
  Blocks: {
    default: [ConversationResource];
  };
}> {
  conversationResource = ConversationResource.from(this, () => this.args);
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationResource': typeof ConversationResourceComponent;
    'inbox2/conversation-resource': typeof ConversationResourceComponent;
  }
}
