/* import __COLOCATED_TEMPLATE__ from './conversation-details.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import type Conversation from 'embercom/objects/inbox/conversation';
import { type SupportedSidebarSection } from 'embercom/services/inbox-sidebar-service';
import type AdminWithPermissions from 'embercom/objects/inbox/admin-with-permissions';
import type InboxState from 'embercom/services/inbox-state';
import type TeamSummary from 'embercom/objects/inbox/team-summary';
import type LinkedTicketsResource from './linked-tickets-resource';

// @ts-ignore
import { trackedReset } from 'tracked-toolbox';

interface Args {
  conversation: Conversation;
  sidebarSections?: SupportedSidebarSection[];
  onSelectTicketType: (ticketTypeId?: number) => void;
  onClose: () => unknown;
  previewOnly?: boolean;
  linkedTicketsResource: LinkedTicketsResource;
}

interface Signature {
  Args: Args;
}

export default class ConversationDetails extends Component<Signature> {
  @service declare inboxState: InboxState;

  @trackedReset('args.conversation.firstParticipant') userId =
    this.args.conversation.firstParticipant?.id || '';

  @action onAdminUpdate(admin: AdminWithPermissions) {
    this.inboxState.assignConversationToAdmin(this.args.conversation, admin);
  }

  @action onTeamUpdate(team: TeamSummary) {
    this.inboxState.assignConversationToTeam(this.args.conversation, team);
  }

  @action onTicketTypeToAddToConversation() {
    return;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationDetails': typeof ConversationDetails;
    'inbox2/conversation-details': typeof ConversationDetails;
  }
}
