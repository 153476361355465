/* import __COLOCATED_TEMPLATE__ from './conversation-attribute-updated.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { EntityType } from 'embercom/models/data/entity-types';
import type Session from 'embercom/services/session';
import type RenderablePart from 'embercom/objects/inbox/renderable-part';
import type ConversationAttributeUpdated from 'embercom/objects/inbox/renderable/conversation-attribute-updated';
import LinkWithText from 'embercom/helpers/link-with-text';
import type AdminSummary from 'embercom/objects/inbox/admin-summary';
import type UserSummary from 'embercom/objects/inbox/user-summary';
import { type WorkflowObject } from 'embercom/objects/inbox/interfaces/workflow';
import type IntlService from 'embercom/services/intl';
import TruncateString from 'embercom/lib/truncate-string';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

interface ConversationAttributeUpdatedPart extends RenderablePart {
  renderableData: ConversationAttributeUpdated;
}

interface Args {
  part: ConversationAttributeUpdatedPart;
}

interface Signature {
  Args: Args;
}

export default class ConversationAttributeUpdatedComponent extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;

  MAXIMUM_UNTRUNCATED_LENGTH = 50;
  TRUNCATE_TO = 30;
  ATTRIBUTES_HIDE_OPERATOR: Array<number | string> = ['brand'];

  // @ts-ignore helper doesn't seem to be typed correctly
  linkWithTextHelper = LinkWithText.create(this);

  get translationData() {
    switch (this.renderableData.entityType) {
      case EntityType.Admin:
        if (this.teammate.id === this.session.teammate.id) {
          return this.youTranslationData;
        } else {
          return this.teammateTranslationData;
        }
      case EntityType.Bot:
        if (this.ATTRIBUTES_HIDE_OPERATOR.includes(this.attributeId)) {
          return this.passiveTranslationData;
        } else {
          return this.teammateTranslationData;
        }
      case EntityType.User:
        return this.userTranslationData;
      case EntityType.Workflow:
        return this.workflowTranslationData;
      default: {
        return { key: 'unknown-conversation-attribute-update' };
      }
    }
  }

  get renderableData() {
    return this.args.part.renderableData;
  }

  get teammate() {
    return this.renderableData.creatingEntity.object as AdminSummary;
  }

  get teammateLink() {
    return this.linkWithTextHelper.compute([
      'apps.app.admins.admin',
      this.teammate.id,
      this.teammate.name,
    ]);
  }

  get value() {
    if (this.valueTooLong) {
      return TruncateString(this.renderableData.value, this.TRUNCATE_TO, false);
    } else {
      return this.fullLengthValue;
    }
  }

  get valueWithLineBreaks() {
    return this.fullLengthValue.replaceAll('\n', '<br />');
  }

  get fullLengthValue() {
    return sanitizeHtml(`${this.renderableData.value}`).string;
  }

  get valueTooLong() {
    return this.fullLengthValue.length > this.MAXIMUM_UNTRUNCATED_LENGTH;
  }

  get renderableDataHasValue() {
    return Array.isArray(this.renderableData.value)
      ? this.renderableData.value.length > 0
      : this.renderableData.value;
  }

  get youTranslationData() {
    if (this.renderableDataHasValue) {
      return {
        key: 'you-set-value',
        data: {
          attribute: this.attributeName,
          value: this.value,
        },
      };
    } else {
      return {
        key: 'you-unset-value',
        data: {
          attribute: this.attributeName,
        },
      };
    }
  }

  get teammateTranslationData() {
    if (this.renderableDataHasValue) {
      return {
        key: 'entity-set-value',
        data: {
          who: this.teammateLink,
          attribute: this.attributeName,
          value: this.value,
        },
      };
    } else {
      return {
        key: 'entity-unset-value',
        data: {
          who: this.teammateLink,
          attribute: this.attributeName,
        },
      };
    }
  }

  get userTranslationData() {
    let user = this.renderableData.entity as UserSummary;

    if (this.renderableDataHasValue) {
      return {
        key: 'entity-set-value',
        data: {
          who: user.displayAs,
          attribute: this.attributeName,
          value: this.value,
        },
      };
    } else {
      return {
        key: 'entity-unset-value',
        data: {
          who: user.displayAs,
          attribute: this.attributeName,
        },
      };
    }
  }

  get passiveTranslationData() {
    if (this.renderableDataHasValue) {
      return {
        key: 'passive-set-value',
        data: {
          attribute: this.attributeName,
          value: this.value,
        },
      };
    } else {
      return {
        key: 'passive-unset-value',
        data: {
          attribute: this.attributeName,
        },
      };
    }
  }

  get workflowTranslationData() {
    let workflow = this.renderableData.entity as WorkflowObject;
    if (this.renderableDataHasValue) {
      return {
        key: 'workflow-set-value',
        data: {
          workflow: workflow.label,
          attribute: this.attributeName,
          value: this.value,
        },
      };
    } else {
      return {
        key: 'workflow-unset-value',
        data: {
          workflow: workflow.label,
          attribute: this.attributeName,
        },
      };
    }
  }

  get attributeName() {
    let attributeSummary = this.renderableData.attributeSummary;
    if (attributeSummary?.isBuiltIn) {
      return this.intl.t(`inbox.ticket-attributes.default-attributes.${attributeSummary.name}`);
    }
    return attributeSummary.name;
  }

  get attributeId() {
    return this.renderableData.attributeSummary.id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Inbox2::ConversationStream::BotFlowParts::ConversationAttributeUpdated': typeof ConversationAttributeUpdatedComponent;
    'inbox2/conversation-stream/bot-flow-parts/conversation-attribute-updated': typeof ConversationAttributeUpdatedComponent;
  }
}
